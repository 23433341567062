<template>
	<div class="home">

		<div class="slogan" style="display: flex;align-items: flex-end;justify-content: space-between;">
			<div>
				您好 {{user.name}}，欢迎来到管理后台。
				<span v-if="1==user.is_root">您的身份是平台超级管理员，权限很大，请务必注意操作安全！！！</span>
				<span v-else-if="1==user.gym.is_super">您的身份是场馆超级管理员，权限很大，请务必注意操作安全</span>
				<span v-else>您的身份是场馆管理员，请注意操作安全。</span>
			</div>
			<div v-if="iscan_trainer_achieve">
				<el-button type="danger" size="mini" @click="$router.replace('/user_trainerachieve');">教练业绩</el-button>
			</div>
		</div>
		<div class="home-hr"></div>

		<div class="title">
			<i class="el-icon-school"></i>
			&nbsp;&nbsp;
			场馆信息（在手机端设置/上传）
			&nbsp;&nbsp;
		</div>
		<div v-if="gym" class="nav-list f-r-s" style="height: 160px;margin:25px 0 50px 0;display: flex;align-items: center;justify-content: flex-start;">
			<div v-if="gym.medias && gym.medias.length" style="width:285px;height: 160px; text-align: center;">
				<el-carousel trigger="click" height="160px" style="border-radius: 20px;">
					<el-carousel-item v-for="(item,ix) in gym.medias" :key="ix" style="width:285px; height:160px;">
						<el-image v-if="'image'==item.type" style="width:285px; height:160px;" :src="item.url" fit="cover"></el-image>
						<video v-else-if="'video'==item.type" controls="controls" style="width:285px; height:160px;">
							<source :src="item.url" type="video/mp4" />
						</video>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div style="width: 10px;"></div>
			<div class="nav-box f-c-c bc_golden" style="min-width:250px;height:160px;text-align: left;">
				<div style="margin: 10px;">
					<div class="fs_huge bold">{{gym.name}}</div>
					<div style="height: 10px;"></div>
					<div class="fs_large">联系：+86-{{gym.tel_no}} ({{gym.tel_name}})</div>
					<div style="height: 10px;"></div>
					<div style="display: flex;align-items: flex-start;justify-content: flex-start;">
						<div>地址：</div>
						<div>
							<div>{{gym.address}}</div>
							<div>{{gym.full_address}}</div>
						</div>
					</div>
				</div>
			</div>
			<div style="width: 25px;"></div>
			<div style="width: 160px; height: 160px; text-align: center;">
				<el-image style="width: 160px; height: 160px" :src="gym.mpcode_img_url" fit="contain"></el-image>
			</div>
			<div style="width: 50px;"></div>
			<div style="width: 160px; height: 160px; text-align: center;">
				<el-image style="width: 160px; height: 160px" :src="gym.contract_seal_url" fit="contain"></el-image>
			</div>
		</div>

		<div class="title">
			<i class="el-icon-data-analysis"></i>
			&nbsp;&nbsp;
			人员统计
			&nbsp;&nbsp;
			<el-date-picker v-model="dr_user" type="daterange" align="right" size="mini" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drOpts" @change="changeDrUser"></el-date-picker>
		</div>
		<div class="nav-list f-r-s" style="height:225px;margin-bottom:50px;overflow-y:hidden;overflow: auto;white-space: nowrap">
			<div class="nav-box f-c-c bc_golden" style="min-width:240px;">
				<div class="num">{{user_new_count}}/{{user_active_count}}</div>
				<div class="name">新增/活跃会员</div>
			</div>
			<div class="nav-box f-c-c bc_red" style="min-width:240px;">
				<div class="num">{{vip_total}}</div>
				<div class="name">会员总数</div>
			</div>
			<div class="nav-box f-c-c bc_orange" style="min-width:240px;">
				<div class="num">{{trainer_total}}</div>
				<div class="name">教练总数</div>
			</div>
			<div class="nav-box f-c-c bc_cyan" style="min-width:240px;">
				<div class="num">{{adminer_total}}</div>
				<div class="name">员工总数</div>
			</div>
			<div class="nav-box f-c-c bc_blue" style="min-width:240px;">
				<div class="num">{{course_total}}</div>
				<div class="name">课程总数</div>
			</div>
			<div class="nav-box f-c-c bc_purple" style="min-width:240px;">
				<div class="num">{{planpage_total}}</div>
				<div class="name">规划模板总数</div>
			</div>
		</div>

		<div class="title">
			<i class="el-icon-data-line"></i>
			&nbsp;&nbsp;
			服务训练
			&nbsp;&nbsp;
			<el-date-picker v-model="dr_train" type="daterange" align="right" size="mini" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drOpts" @change="changeDrTrain"></el-date-picker>
			&nbsp;&nbsp;
			<el-radio-group size="mini" v-model="dr_train_cate" @change="changeDrTrain">
				<el-radio-button label="day">天</el-radio-button>
				<el-radio-button label="month">月</el-radio-button>
				<el-radio-button label="year">年</el-radio-button>
			</el-radio-group>
		</div>
		<!-- <div class="nav-list f-r-s" style="margin-bottom:50px;overflow-y:hidden;overflow: auto;white-space: nowrap"> -->
		<div style="margin-bottom:50px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div class="txtrow">新增合同：{{contract_count}}<span style="margin-left: 25px;">合同总数：{{contract_total}}</span></div>
					<el-radio-group size="mini" v-model="contract_ctype" @change="drawContract">
						<el-radio-button label="bar"><i class="el-icon-s-data"></i></el-radio-button>
						<el-radio-button label="line"><i class="el-icon-s-marketing"></i></el-radio-button>
					</el-radio-group>
				</div>
				<div id="echartContract" ref="echartContract" style="width:400px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div class="txtrow">新增训练规划：{{plan_count}}<span style="margin-left: 25px;">训练规划总数：{{plan_total}}</span></div>
					<el-radio-group size="mini" v-model="plan_ctype" @change="drawPlan">
						<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
						<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
					</el-radio-group>
				</div>
				<div id="echartPlan" ref="echartPlan" style="width:400px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div class="txtrow">新增训练效果：{{effect_count}}<span style="margin-left: 25px;">训练效果总数：{{effect_total}}</span></div>
					<el-radio-group size="mini" v-model="effect_ctype" @change="drawEffect">
						<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
						<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
					</el-radio-group>
				</div>
				<div id="echartEffect" ref="echartEffect" style="width:400px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div>
						<div class="txtrow">上报：{{handup_count}}</div>
						<el-radio-group size="mini" v-model="handup_ctype" @change="drawHandupLeavemsg">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 25px;"></div>
					<div>
						<div class="txtrow">会员：{{leavemsg_count}}</div>
						<el-radio-group size="mini" v-model="leavemsg_ctype" @change="drawHandupLeavemsg">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div id="echartHandupLeavemsg" ref="echartHandupLeavemsg" style="width:400px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div>
						<div class="txtrow">问卷：{{surveyfaq_count}}</div>
						<el-radio-group size="mini" v-model="surveyfaq_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">体适能：{{surveypft_count}}</div>
						<el-radio-group size="mini" v-model="surveypft_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">静态：{{surveypfts_count}}</div>
						<el-radio-group size="mini" v-model="surveypfts_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">动态：{{surveypftd_count}}</div>
						<el-radio-group size="mini" v-model="surveypftd_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">FMS：{{surveyfms_count}}</div>
						<el-radio-group size="mini" v-model="surveyfms_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">性格测试：{{surveyfpa_count}}</div>
						<el-radio-group size="mini" v-model="surveyfpa_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div id="echartSurvey" ref="echartSurvey" style="width:850px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
		</div>

	</div>
</template>
<script>
	import * as echarts from 'echarts';
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'home',
		data() {
			return {
				gym_id: 0,

				gym: '',

				drOpts: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				dr_user: '',
				user_active_count: 0,
				user_new_count: 0,
				vip_total: 0,
				trainer_total: 0,
				adminer_total: 0,
				course_total: 0,
				planpage_total: 0,

				dr_train: '',
				dr_train_cate: 'month',
				contract_ctype: 'bar',
				contract_count: 0,
				contract_total: 0,
				echartContract: '',
				plan_ctype: 'bar',
				plan_count: 0,
				plan_total: 0,
				echartPlan: '',
				effect_ctype: 'bar',
				effect_count: 0,
				effect_total: 0,
				echartEffect: '',
				handup_ctype: 'bar',
				handup_count: 0,
				leavemsg_ctype: 'line',
				leavemsg_count: 0,
				echartHandupLeavemsg: '',
				surveyfaq_ctype: 'line',
				surveyfaq_count: 0,
				surveypft_ctype: 'line',
				surveypft_count: 0,
				surveypfts_ctype: 'line',
				surveypfts_count: 0,
				surveypftd_ctype: 'line',
				surveypftd_count: 0,
				surveyfms_ctype: 'line',
				surveyfms_count: 0,
				surveyfpa_ctype: 'bar',
				surveyfpa_count: 0,
				echartSurvey: ''
			}
		},
		computed: {
			...mapState(['user']),
			iscan_trainer_achieve() {
				if (1 == this.user.is_root) {
					return true
				} else if (1 == this.user.gym.is_super) {
					return true
				} else {
					let iscan = false
					this.user.gym.rules.forEach((item, ix) => {
						if (item && 'user_trainerachieve' == item.name) {
							iscan = true
						}
					})
					return iscan
				}
			}
		},
		mounted() {
			this.gym_id = this.user.gym.id
			this.init()
		},
		methods: {
			init() {
				let _data = {
					gym_id: this.gym_id,
					dr_train_cate: this.dr_train_cate
				}
				if (this.dr_user && 2 == this.dr_user.length) {
					_data['from_dt_user'] = Math.floor(this.dr_user[0] / 1000)
					_data['to_dt_user'] = Math.floor(this.dr_user[1] / 1000)
				}
				if (this.dr_train && 2 == this.dr_train.length) {
					_data['from_dt_train'] = Math.floor(this.dr_train[0] / 1000)
					_data['to_dt_train'] = Math.floor(this.dr_train[1] / 1000)
				}
				this.$post('/admin/Ctrlboard/getHomePageInfo', _data).then(res => {
					if (res.rst == 0) {
						res = res.data
						this.gym = res.gym
						if (this.gym && !this.gym.mpcode_img_url) {
							this.$post("/mob/Devops/getMpcodeImgcode", {
								gym_id: this.gym_id,
								line_color: '{"r":48,"g":48,"b":48}'
							}).then(res => {
								if (res.rst == 0) {
									this.gym.mpcode_img_url = res.data
								}
							}).finally(() => {})
						}

						if (!this.dr_user || 2 != this.dr_user.length) {
							this.dr_user = [1000 * res.from_dt_user, 1000 * res.to_dt_user]
						}
						if (!this.dr_train || 2 != this.dr_train.length) {
							this.dr_train = [1000 * res.from_dt_train, 1000 * res.to_dt_train]
						}
						//this.$forceUpdate()
						this.user_active_count = res.user_active_count ? res.user_active_count : 0;
						this.user_new_count = res.user_new_count ? res.user_new_count : 0;
						this.vip_total = res.vip_total ? res.vip_total : 0;
						this.trainer_total = res.trainer_total ? res.trainer_total : 0;
						this.adminer_total = res.adminer_total ? res.adminer_total : 0;
						this.course_total = res.course_total ? res.course_total : 0;
						this.planpage_total = res.planpage_total ? res.planpage_total : 0;
						this.drawTrain(res)

						this.system_params = res.system_params ? res.system_params : [];
					}
				})
			},
			changeDrUser() {
				this.user_active_count = 0
				this.user_new_count = 0
				this.vip_total = 0
				this.trainer_total = 0
				this.adminer_total = 0
				let _data = {
					gym_id: this.gym_id
				}
				if (this.dr_user && 2 == this.dr_user.length) {
					_data['from_dt'] = Math.floor(this.dr_user[0] / 1000)
					_data['to_dt'] = Math.floor(this.dr_user[1] / 1000)
				}
				this.$post('/admin/Ctrlboard/getHomeStatUser', _data).then(res => {
					if (res.rst == 0) {
						res = res.data
						this.user_active_count = res.user_active_count ? res.user_active_count : 0;
						this.user_new_count = res.user_new_count ? res.user_new_count : 0;
						this.vip_total = res.vip_total ? res.vip_total : 0;
						this.trainer_total = res.trainer_total ? res.trainer_total : 0;
						this.adminer_total = res.adminer_total ? res.adminer_total : 0;
						this.course_total = res.course_total ? res.course_total : 0;
						this.planpage_total = res.planpage_total ? res.planpage_total : 0;
					}
				})
			},
			changeDrTrain() {
				this.contract_count = 0
				this.contract_total = 0
				this.contract_xs = []
				this.contract_ys = []
				this.plan_count = 0
				this.plan_total = 0
				this.plan_xs = []
				this.plan_ys = []
				let _data = {
					gym_id: this.gym_id,
					dr_train_cate: this.dr_train_cate
				}
				if (this.dr_train && 2 == this.dr_train.length) {
					_data['from_dt'] = Math.floor(this.dr_train[0] / 1000)
					_data['to_dt'] = Math.floor(this.dr_train[1] / 1000)
				}
				this.$post('/admin/Ctrlboard/getHomeStatTrain', _data).then(res => {
					if (res.rst == 0) {
						res = res.data
						this.drawTrain(res)
					}
				})
			},
			drawTrain(res) {
				this.contract_count = res.contract_count ? res.contract_count : 0;
				this.contract_total = res.contract_total ? res.contract_total : 0;
				this.contract_xs = []
				this.contract_ys = []
				Object.keys(res.contract_vs).forEach((item, ix) => {
					this.contract_xs.push(item)
					this.contract_ys.push(res.contract_vs[item])
				})
				this.drawContract()
				this.plan_count = res.plan_count ? res.plan_count : 0;
				this.plan_total = res.plan_total ? res.plan_total : 0;
				this.plan_xs = []
				this.plan_ys = []
				Object.keys(res.plan_vs).forEach((item, ix) => {
					this.plan_xs.push(item)
					this.plan_ys.push(res.plan_vs[item])
				})
				this.drawPlan()
				this.effect_count = res.effect_count ? res.effect_count : 0;
				this.effect_total = res.effect_total ? res.effect_total : 0;
				this.effect_xs = []
				this.effect_ys = []
				Object.keys(res.effect_vs).forEach((item, ix) => {
					this.effect_xs.push(item)
					this.effect_ys.push(res.effect_vs[item])
				})
				this.drawEffect()
				this.handup_count = res.handup_count ? res.handup_count : 0;
				this.handup_xs = []
				this.handup_ys = []
				Object.keys(res.handup_vs).forEach((item, ix) => {
					this.handup_xs.push(item)
					this.handup_ys.push(res.handup_vs[item])
				})
				this.leavemsg_count = res.leavemsg_count ? res.leavemsg_count : 0;
				this.leavemsg_xs = []
				this.leavemsg_ys = []
				Object.keys(res.leavemsg_vs).forEach((item, ix) => {
					this.leavemsg_xs.push(item)
					this.leavemsg_ys.push(res.leavemsg_vs[item])
				})
				this.drawHandupLeavemsg()
				this.surveyfaq_count = res.surveyfaq_count ? res.surveyfaq_count : 0;
				this.surveyfaq_xs = []
				this.surveyfaq_ys = []
				Object.keys(res.surveyfaq_vs).forEach((item, ix) => {
					this.surveyfaq_xs.push(item)
					this.surveyfaq_ys.push(res.surveyfaq_vs[item])
				})
				this.surveypft_count = res.surveypft_count ? res.surveypft_count : 0;
				this.surveypft_xs = []
				this.surveypft_ys = []
				Object.keys(res.surveypft_vs).forEach((item, ix) => {
					this.surveypft_xs.push(item)
					this.surveypft_ys.push(res.surveypft_vs[item])
				})
				this.surveypfts_count = res.surveypfts_count ? res.surveypfts_count : 0;
				this.surveypfts_xs = []
				this.surveypfts_ys = []
				Object.keys(res.surveypfts_vs).forEach((item, ix) => {
					this.surveypfts_xs.push(item)
					this.surveypfts_ys.push(res.surveypfts_vs[item])
				})
				this.surveypftd_count = res.surveypftd_count ? res.surveypftd_count : 0;
				this.surveypftd_xs = []
				this.surveypftd_ys = []
				Object.keys(res.surveypftd_vs).forEach((item, ix) => {
					this.surveypftd_xs.push(item)
					this.surveypftd_ys.push(res.surveypftd_vs[item])
				})
				this.surveyfms_count = res.surveyfms_count ? res.surveyfms_count : 0;
				this.surveyfms_xs = []
				this.surveyfms_ys = []
				Object.keys(res.surveyfms_vs).forEach((item, ix) => {
					this.surveyfms_xs.push(item)
					this.surveyfms_ys.push(res.surveyfms_vs[item])
				})
				this.surveyfpa_count = res.surveyfpa_count ? res.surveyfpa_count : 0;
				this.surveyfpa_xs = []
				this.surveyfpa_ys = []
				Object.keys(res.surveyfpa_vs).forEach((item, ix) => {
					this.surveyfpa_xs.push(item)
					this.surveyfpa_ys.push(res.surveyfpa_vs[item])
				})
				this.drawSurvey()
			},
			drawContract() {
				this.echartContract = echarts.init(document.getElementById('echartContract'))
				this.echartContract.showLoading();
				this.echartContract.setOption({
					xAxis: {
						type: 'category',
						data: this.contract_xs
					},
					yAxis: {
						type: 'value'
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '合同',
						data: this.contract_ys,
						type: this.contract_ctype,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#F0E0C5'
								},
								{
									offset: 0.2,
									color: '#E0D0B5'
								},
								{
									offset: 1,
									color: '#D0C0A5'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D0C0A5'
									},
									{
										offset: 0.8,
										color: '#E0D0B5'
									},
									{
										offset: 1,
										color: '#F0E0C5'
									}
								])
							}
						}
					}]
				})
				this.echartContract.hideLoading()
			},
			drawPlan() {
				this.echartPlan = echarts.init(document.getElementById('echartPlan'))
				this.echartPlan.showLoading();
				this.echartPlan.setOption({
					xAxis: {
						type: 'category',
						data: this.plan_xs
					},
					yAxis: {
						type: 'value'
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '训练规划',
						data: this.plan_ys,
						type: this.plan_ctype,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#F0E0C5'
								},
								{
									offset: 0.2,
									color: '#E0D0B5'
								},
								{
									offset: 1,
									color: '#D0C0A5'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D0C0A5'
									},
									{
										offset: 0.8,
										color: '#E0D0B5'
									},
									{
										offset: 1,
										color: '#F0E0C5'
									}
								])
							}
						}
					}]
				})
				this.echartPlan.hideLoading()
			},
			drawEffect() {
				this.echartEffect = echarts.init(document.getElementById('echartEffect'))
				this.echartEffect.showLoading();
				this.echartEffect.setOption({
					xAxis: {
						type: 'category',
						data: this.effect_xs
					},
					yAxis: {
						type: 'value'
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '训练效果',
						data: this.effect_ys,
						type: this.effect_ctype,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#F0E0C5'
								},
								{
									offset: 0.2,
									color: '#E0D0B5'
								},
								{
									offset: 1,
									color: '#D0C0A5'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D0C0A5'
									},
									{
										offset: 0.8,
										color: '#E0D0B5'
									},
									{
										offset: 1,
										color: '#F0E0C5'
									}
								])
							}
						}
					}]
				})
				this.echartEffect.hideLoading()
			},
			drawHandupLeavemsg() {
				this.echartHandupLeavemsg = echarts.init(document.getElementById('echartHandupLeavemsg'))
				this.echartHandupLeavemsg.showLoading();
				this.echartHandupLeavemsg.setOption({
					xAxis: {
						type: 'category',
						data: this.handup_xs
					},
					yAxis: {
						type: 'value'
					},
					legend: {
						data: ['上报数据', '会员留言']
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '上报数据',
						data: this.handup_ys,
						type: this.handup_ctype,
						smooth: true,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#F0E0C5'
								},
								{
									offset: 0.2,
									color: '#E0D0B5'
								},
								{
									offset: 1,
									color: '#D0C0A5'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D0C0A5'
									},
									{
										offset: 0.8,
										color: '#E0D0B5'
									},
									{
										offset: 1,
										color: '#F0E0C5'
									}
								])
							}
						}
					}, {
						name: '会员留言',
						data: this.leavemsg_ys,
						type: this.leavemsg_ctype,
						smooth: true,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#FF6659'
								},
								{
									offset: 0.2,
									color: '#F75649'
								},
								{
									offset: 1,
									color: '#E74639'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#E74639'
									},
									{
										offset: 0.8,
										color: '#F75649'
									},
									{
										offset: 1,
										color: '#FF6659'
									}
								])
							}
						}
					}]
				})
				this.echartHandupLeavemsg.hideLoading()
			},
			drawSurvey() {
				this.echartSurvey = echarts.init(document.getElementById('echartSurvey'))
				this.echartSurvey.showLoading();
				this.echartSurvey.setOption({
					xAxis: {
						type: 'category',
						data: this.surveyfaq_xs
					},
					yAxis: {
						type: 'value'
					},
					legend: {
						data: ['问卷调查', '体适能测评', '静态测评', '动态测评', 'FMS测评', '性格色彩']
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '问卷调查',
						data: this.surveyfaq_ys,
						type: this.surveyfaq_ctype,
						smooth: true
					}, {
						name: '体适能测评',
						data: this.surveypft_ys,
						type: this.surveypft_ctype,
						smooth: true
					}, {
						name: '静态测评',
						data: this.surveypfts_ys,
						type: this.surveypfts_ctype,
						smooth: true
					}, {
						name: '动态测评',
						data: this.surveypftd_ys,
						type: this.surveypftd_ctype,
						smooth: true
					}, {
						name: 'FMS测评',
						data: this.surveyfms_ys,
						type: this.surveyfms_ctype,
						smooth: true
					}, {
						name: '性格色彩',
						data: this.surveyfpa_ys,
						type: this.surveyfpa_ctype,
						smooth: true
					}]
				})
				this.echartSurvey.hideLoading()
			}
		}
	}
</script>
<style lang="less">
	.home {
		.slogan {
			height: 30px;
			line-height: 30px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
		}

		.home-hr {
			width: 100%;
			height: 1px;
			background: #F0F0F0;
		}

		.title {
			font-weight: 500;
			color: #333333;
			font-size: 20px;
			margin: 30px 0;
		}

		.nav-list {
			.nav-box {
				margin-right: 50px;
				height: 180px;
				border-radius: 20px;
				box-shadow: 3px 3px 7px 0px rgba(102, 102, 102, 0.35);

				.num {
					font-size: 50px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #FFFFFF;
				}

				.name {
					font-size: 28px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #FFFFFF;
				}
			}

			.nav-box-w {
				width: 400px;
			}

			//num_colors1: ['#C0E0EA', '#FFE5CC', '#C7F5F5', '#FCDBDB', '#FFF8C6', '#CFFEEE', '#E7D4FF'],
			//num_colors2: ['#33B4D9', '#FF8F1F', '#07B9B9', '#FA5151', '#FFC300', '#00B578', '#8A38F5'],
			.bc_cyan {
				background: linear-gradient(138deg, #23a4c9, #33B4D9);
				color: white;
			}

			.bc_purple {
				background: linear-gradient(138deg, #7A28E5, #8A38F5);
				color: white;
			}

			.box0 {
				background: linear-gradient(138deg, #F0E0C5, #d0c0a5);
			}

			.box1 {
				background: linear-gradient(138deg, #A5F46E, #6FC334);
			}

			.box2 {
				background: linear-gradient(138deg, #FFDBA6, #ED991F);
			}

			.box3 {
				background: linear-gradient(138deg, #C0E0EA, #33B4D9);
			}

			.box4 {
				background: linear-gradient(138deg, #FF9F7E, #EB5753);
			}

			.box5 {
				background: linear-gradient(138deg, #C7F5F5, #07B9B9);
			}

			.box6 {
				background: linear-gradient(138deg, #E191FF, #9959F8);
			}

			.box7 {
				background: linear-gradient(138deg, #9AC7FF, #569EF8);
			}

		}
	}
</style>