<template>
	<div class="vip">
		<div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
			<div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
				<el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入会员手机号或姓名" clearable @clear="onSearchClick">
					<el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
				</el-input>
				<el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="goAdd">
					新增
				</el-button>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="status" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="0">删除</el-radio-button>
					<el-radio-button label="1">正常</el-radio-button>
				</el-radio-group>
			</div>
			<div class="filters">
				<el-checkbox v-model="only_vip" @change="onSearchClick">仅会员（不显示教练、员工）</el-checkbox>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="weichat_or_phoneno" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="nowechat">无微信号会员</el-radio-button>
					<el-radio-button label="nophoneno">无手机号会员</el-radio-button>
				</el-radio-group>
			</div>
		</div>

		<div class="fc_red">注：小程序本质上仅接受微信唯一识别码OPENID；通过手机号添加会员是一种预加入会员机制，强烈要求添加前通过 “无手机号会员” 按钮筛选会员微信账号并设置手机号。</div>
		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
			<el-table-column label="头像" width="100">
				<template slot-scope="scope">
					<el-image class="border" fit="contain" :src="scope.row.vip.avatar_url" style="width: 90px;height: 90px;"></el-image>
				</template>
			</el-table-column>
			<el-table-column label="账号信息">
				<template slot-scope="scope">
					<div>姓名昵称：{{ scope.row.vip.name }}（{{scope.row.vip.nickname}} {{ scope.row.vip.sex == 1 ? "男" : "女" }}）</div>
					<div>双重账号：
						<span v-if="scope.row.vip.wechat_openid">{{scope.row.vip.wechat_openid.substr(0,3)+'***'+scope.row.vip.wechat_openid.substr(-3)}}</span>
						<span v-else><el-button type="text" size="mini" @click="showMpCode()">补充</el-button></span>
						<span v-if="scope.row.vip.phoneno" style="margin-left: 5px;">{{ scope.row.vip.phoneno }}</span>
						<span v-else style="margin-left: 5px;"><el-button type="text" size="mini" @click="setPhoneno(scope.row.vip.id)">补充</el-button></span>
					</div>
					<div>出生生日：<span v-if="scope.row.vip.birth_dt>0">{{ scope.row.vip.birth_dt | formatDate}}<span v-if="scope.row.vip.age>=0">（{{scope.row.vip.age}}岁）</span></span></div>
					<div style="display: flex;align-items: center;">加入时间：{{scope.row.create_dt | formatDate}}<el-image v-for="th in 5" :key="th" src="https://dalisfit-gym.oss-cn-hangzhou.aliyuncs.com/static/medal.png" fit="contain" style="width:15px;height:15px;" :style="{'filter':th>scope.row.medal_count?'grayscale(100%)':''}" /></div>
				</template>
			</el-table-column>
			<el-table-column label="加入场馆">
				<template slot-scope="scope">
					<div>是否场馆教练：{{1==scope.row.is_trainer?'是':'否'}}<i v-if="1==scope.row.is_trainer" v-for="th in 5" :key="th" class="el-icon-star-off" :class="th>scope.row.trainer_level?'fc_gray':'fc_orange'" style="margin-left:5px;"></i></div>
					<div>是否场馆员工：{{1==scope.row.is_adminer?'是':'否'}}</div>
					<div>我的负责教练：<span v-if="scope.row.trainer"> {{ scope.row.trainer.name}}({{scope.row.trainer.nickname}} {{scope.row.trainer.phoneno|formatPhoneno}})</span></div>
					<div>我的第一教练：<span v-if="scope.row.trainer_1st"> {{ scope.row.trainer_1st.name}}({{scope.row.trainer_1st.nickname}} {{scope.row.trainer_1st.phoneno|formatPhoneno}})</span></div>
				</template>
			</el-table-column>
			<el-table-column label="课前测评">
				<template slot-scope="scope">
					<div class="txtrow" style="display: flex;align-items: center;justify-content: space-between;">
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_fpa',scope.row.id);">性格色彩</el-button>
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_faq',scope.row.id);">问卷调查</el-button>
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_more',scope.row.id);">自定义测评</el-button>
					</div>
					<div class="txtrow" style="display: flex;align-items: center;justify-content: space-between;">
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_bm',scope.row.id);">身体测评</el-button>
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_hs',scope.row.id);">健康筛查</el-button>
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_fms',scope.row.id);">FMS测评</el-button>
					</div>
					<div class="txtrow" style="display: flex;align-items: center;justify-content: space-between;">
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_pft',scope.row.id);">体适能测评</el-button>
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_pfts',scope.row.id);">静态测评</el-button>
						<el-button type="text" style="width: 75px;padding:5px 0;text-align: left;" @click="gotoPageSurvey('/survey_pftd',scope.row.id);">动态测评</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="" width="60">
				<template slot-scope="scope">
				</template>
			</el-table-column>
			<el-table-column label="状态" width="50">
				<template slot-scope="scope">
					<span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button-group>
						<el-button v-bind:disabled="0==scope.row.status" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
						<el-button v-bind:disabled="0==scope.row.status" type="danger" size="small" @click="onStatus(scope.row.id, 0)">删除</el-button>
						<el-button v-bind:disabled="1==scope.row.status" type="success" size="small" @click="onStatus(scope.row.id, 1)">正常</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
				</el-pagination>
			</div>
		</div>

		<el-dialog class="form-wrap" :title="showAddModal ? '新增会员' : '修改会员'" :visible.sync="showModal" :before-close="onCloseModal">
			<div v-if="mguOnSet.vip_wechat_openid" class="fc_gray">小程序微信账号：{{mguOnSet.vip_wechat_openid}}</div>
			<div v-else class="fc_red">注：小程序本质上仅接受微信唯一识别码OPENID；此处手机号预添加，还需要扫小程序码并绑定此手机号，正式成为会员！</div>
			<el-form class="form-wrap" @submit.native.prevent ref="user-form" :rules="rule" :model="mguOnSet" label-position="left" style="padding: 30px 10px">
				<el-row :gutter="20">
					<el-col :span="10" :offset="1" class="border" style="background: #F8F8F8;">
						<el-form-item label="手机号" prop="vip_phoneno" style="height: 80px;">
							<el-input v-model="mguOnSet.vip_phoneno" :disabled="showSetModal" @input="inputPhoneno" />
						</el-form-item>
						<el-form-item label="姓名" prop="vip_name">
							<el-input v-model="mguOnSet.vip_name" @input="inputName" />
						</el-form-item>
						<el-form-item label="昵称/英文名">
							<el-input v-model="mguOnSet.vip_nickname" @input="inputNickname" />
						</el-form-item>
						<el-form-item label="出生生日" prop="birth_dt" style="height: 80px;">
							<el-date-picker v-model="mguOnSet.vip_birth_dt" value-format="timestamp" type="date" placeholder="选择日期" style="width: 100%;"></el-date-picker>
						</el-form-item>
						<el-form-item label="性别">
							<el-radio-group v-model="mguOnSet.vip_sex" @input="changeSex">
								<el-radio :label="1">男</el-radio>
								<el-radio :label="0">女</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item v-if="showAddModal" label="如果手机账号存在，是否覆盖？">
							<el-radio-group v-model="user_foradd_iscover">
								<el-radio :label="1">覆盖</el-radio>
								<el-radio :label="0">不覆盖</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2" class="border" style="background: #F8F8F8;">
						<el-form-item label="" prop="medal_count" style="height: 80px;">
							<div>{{'收获奖章 '+mguOnSet.medal_count+'枚'}}<el-button v-if="mguOnSet.medal_count" type="text" size="medium" style="margin-left: 10px;" @click="mguOnSet.medal_count = 0;">归零</el-button></div>
							<div style="height: 40px;"><el-image v-for="(item,ix) in 5" :key="ix" src="https://dalisfit-gym.oss-cn-hangzhou.aliyuncs.com/static/medal.png" fit="contain" style="width:30px;height:30px;" :style="{'filter':ix>=mguOnSet.medal_count?'grayscale(100%)':''}" @click="mguOnSet.medal_count = (1 + ix);" /></div>
						</el-form-item>
						<el-form-item label="主教练">
							<el-select v-model="mguOnSet.trainer_id" filterable remote clearable placeholder="请输入教练姓名或手机号" :remote-method="remoteMethod" :loading="loading" style="width: 100%;" @focus="focusTrainers">
								<el-option v-for="(item,ix) in trainer_opts" :key="item.id" :label="item.name+' '+item.phoneno" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="第一教练">
							<el-select v-model="mguOnSet.trainer_id_1st" filterable remote clearable placeholder="请输入教练姓名或手机号" :remote-method="remoteMethod" :loading="loading" style="width: 100%;" @focus="focusTrainers">
								<el-option v-for="(item,ix) in trainer_opts" :key="item.id" :label="item.name+' '+item.phoneno" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" style="height: 80px;">
							<div>是否教练<el-button v-if="mguOnSet.is_trainer && mguOnSet.trainer_level" type="text" size="medium" style="margin-left: 10px;" @click="mguOnSet.trainer_level = 0;">零颗星</el-button></div>
							<div style="height:40px;display: flex;align-items: center;justify-content: space-between;">
								<el-radio-group v-model="mguOnSet.is_trainer">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
								<i v-if="mguOnSet.is_trainer" v-for="th in 5" :key="th" class="el-icon-star-off" :class="th>mguOnSet.trainer_level?'fc_gray':'fc_orange'" @click="mguOnSet.trainer_level=th;"></i>
							</div>
						</el-form-item>
						<el-form-item label="是否员工">
							<el-radio-group v-model="mguOnSet.is_adminer">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="0">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item v-if="showAddModal" label="如果会员信息存在，是否覆盖？">
							<el-radio-group v-model="mgu_foradd_iscover">
								<el-radio :label="1">覆盖</el-radio>
								<el-radio :label="0">不覆盖</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" style="margin-top:50px;">
					<el-col :span="22" :offset="1" style="display:flex;align-items: center;justify-content: space-between;">
						<el-form-item label="" :style="{'visibility':(showAddModal||!mguOnSet.vip_wechat_openid)?'hidden':''}">
							<el-button plain @click="initPassword">重置密码</el-button>
							<el-button plain @click="initPayPassword">重置支付密码</el-button>
						</el-form-item>
						<el-form-item label="" style="float: right;">
							<el-button plain @click="onCloseModal">取消</el-button>
							<el-button type="primary" @click="onSave">{{showAddModal?'添加会员':'保存设置'}}</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>

		<el-dialog title="场馆小程序码" width="380px" :visible.sync="showMpcodeModal">
			<div style="text-align: center;">
				<el-image fit="contain" :src="mpcode_url" style="width: 250px;height: 250px;border-radius: 5px;"></el-image>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	import {
		hex,
		hex_hmac
	} from "@/utils/hex.js"
	import {
		mapState
	} from "vuex"
	import {
		randStr
	} from "@/utils/formatData"
	const defaultMgu = {
		vip: {
			phoneno: '',
			wechat_openid: '',
			name: '',
			nickname: '',
			sex: 0,
			birth_dt: 0,
			password: '',
			pay_password: ''
		},
		medal_count: 0,
		is_trainer: 0,
		is_adminer: 0,
		trainer_id: 0,
		trainer_level: 0,
		trainer_id_1st: 0
	}
	const defaultTrainerOpts = {
		id: 0,
		name: '无教练',
		phoneno: ''
	}
	export default {
		name: "user_vip",
		computed: {
			...mapState(["user"]),
			showModal() {
				return this.showAddModal || this.showSetModal;
			},
			pageStart() {
				return (this.page_ix - 1) * this.page_size;
			},
		},
		data() {
			return {
				gym_id: 0,

				only_vip: false,

				cdit: "",
				loading: false,
				list: [],
				status: "1",
				weichat_or_phoneno: "",
				total: 0,
				page_ix: 1,
				page_size: 10,

				default_birth_dt: 0,
				default_nicknames_1: [],
				default_nicknames_0: [],
				user_foradd_iscover: 0,
				mgu_foradd_iscover: 0,


				showMpcodeModal: false,
				mpcode_url: '',

				mguOnSet: {
					...defaultMgu
				},
				trainer_opts: [{
					...defaultTrainerOpts
				}],
				showAddModal: false,
				showSetModal: false,
				rule: {
					vip_phoneno: [{
						required: true,
						message: '请输入会员手机号',
						trigger: ['blur', 'change']
					}, {
						validator: (rule, value, callback) => {
							if (/^1[3456789]\d{9}$/.test(value)) {
								callback();
							} else {
								callback(new Error('请输入正确的手机号'));
							}
						},
						trigger: ['blur', 'change']
					}],
					vip_name: [{
						required: true,
						message: '请输入会员姓名',
						trigger: ['blur', 'change']
					}]
				}
			};
		},
		mounted() {
			this.gym_id = this.user.gym.id
			this.$post("/admin/User/getVipPageInfo", {
				gym_id: this.gym_id
			}).then(res => {
				if (res.rst == 0) {
					res = res.data
					this.default_birth_dt = res.default_birth_dt
					this.default_nicknames_1 = res.default_nicknames_1
					this.default_nicknames_0 = res.default_nicknames_0
					if (res.mpcode_url) {
						this.mpcode_url = res.mpcode_url
					} else {
						this.$post("/mob/Devops/getMpcodeImgcode", {
							gym_id: gym_id,
							line_color: '{"r":48,"g":48,"b":48}'
						}).then(res2 => {
							if (0 == res2.rst) {
								this.mpcode_url = res2.data
							}
						})
					}
				}
			})
			this.getList()
		},
		methods: {
			getList() {
				this.loading = true;
				let _data = {
					gym_id: this.gym_id,
					cdit: this.cdit,
					status: this.status,
					weichat_or_phoneno: this.weichat_or_phoneno,
					page_ix: this.page_ix,
					page_size: this.page_size
				}
				if (this.only_vip) {
					_data['is_trainer'] = 0
					_data['is_adminer'] = 0
				}
				this.$post("/admin/User/queryVip", _data).then(res => {
					if (res.rst == 0) {
						this.list = res.data.data;
						this.total = res.data.total;
					}
				}).finally(() => {
					this.loading = false;
				})
			},
			onSearchClick() {
				this.page_ix = 1;
				this.getList();
			},
			onPageChange(page) {
				this.page_ix = page;
				this.getList();
			},
			showMpCode() {
				this.showMpcodeModal = true
			},
			setPhoneno(vip_id) {
				this.$prompt("该操作将设置会员手机号，确认设置?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					inputPattern: /^1\d{10}$/,
					inputErrorMessage: '手机号格式错误',
					type: "info",
				}).then(({
					value
				}) => {
					this.$post("/admin/User/setVip", {
						vip_id: vip_id,
						phoneno: value
					}).then((res) => {
						if (res.rst == 0) {
							this.list.forEach((item, ix) => {
								if (item.vip && item.vip.id == vip_id) {
									item.vip.phoneno = value
								}
							})
						}
					})
				})
			},
			goAdd() {
				this.trainer_opts = [{
					...defaultTrainerOpts
				}]
				this.mguOnSet = {
					vip_phoneno: defaultMgu.vip.phoneno,
					vip_wechat_openid: defaultMgu.vip.wechat_openid,
					vip_name: '',
					vip_name_inputed: false,
					vip_nickname: '',
					vip_nickname_inputed: false,
					vip_sex: defaultMgu.vip.sex,
					vip_birth_dt: this.default_birth_dt ? 1000 * this.default_birth_dt : '',
					vip_password: '',
					vip_pay_password: '',
					medal_count: defaultMgu.medal_count,
					is_trainer: defaultMgu.is_trainer,
					is_adminer: defaultMgu.is_adminer,
					trainer_id: defaultMgu.trainer_id,
					trainer_level: defaultMgu.trainer_level,
					trainer_id_1st: defaultMgu.trainer_id_1st
				}
				this.showAddModal = true
				this.changeSex(this.mguOnSet.vip_sex)
			},
			inputPhoneno(e) {
				if (this.showAddModal && !this.mguOnSet.vip_name_inputed && e.length) {
					this.mguOnSet.vip_name = e.substr(0, 3) + ' **** ' + e.substr(-3)
				}
			},
			inputName(e) {
				if (this.showAddModal) {
					this.mguOnSet.vip_name_inputed = e.length ? true : false
				}
			},
			changeSex(e) {
				if (this.showAddModal && !this.mguOnSet.vip_nickname_inputed) {
					this.mguOnSet.vip_nickname = 1 == e ? this.default_nicknames_1[Math.floor(Math.random() * this.default_nicknames_1.length)] : this.default_nicknames_0[Math.floor(Math.random() * this.default_nicknames_0.length)]
				}
			},
			inputNickname(e) {
				if (this.showAddModal) {
					this.mguOnSet.vip_nickname_inputed = e.length ? true : false
				}
			},
			goSet(mgu) {
				this.mguOnSet = {
					vip_id: mgu.vip.id,
					vip_phoneno: mgu.vip.phoneno,
					vip_wechat_openid: mgu.vip.wechat_openid,
					vip_name: mgu.vip.name,
					vip_nickname: mgu.vip.nickname,
					vip_sex: mgu.vip.sex,
					vip_birth_dt: mgu.vip.birth_dt ? 1000 * mgu.vip.birth_dt : '',
					vip_password: '',
					vip_pay_password: '',
					mgu_id: mgu.id,
					medal_count: mgu.medal_count,
					is_trainer: mgu.is_trainer,
					is_adminer: mgu.is_adminer,
					trainer_id: mgu.trainer_id,
					trainer: mgu.trainer,
					trainer_level: mgu.trainer_level,
					trainer_id_1st: mgu.trainer_id_1st,
					trainer_1st: mgu.trainer_1st
				}
				this.initTrainerOpts()
				this.showSetModal = true
			},
			focusTrainers() {
				if (1 > this.trainer_opts.length1 || (1 == this.trainer_opts.length && 0 == this.trainer_opts[0].id)) {
					this.remoteMethod('')
				}
			},
			remoteMethod(query = '') {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/User/remoteTrainers", {
						gym_id: this.gym_id,
						cdit: query.trim()
					}).then(res => {
						if (res.rst == 0) {
							this.initTrainerOpts()
							res.data.forEach((item, ix) => {
								if (this.mguOnSet.trainer_id != item.id && this.mguOnSet.trainer_id_1st != item.id) {
									this.trainer_opts.push(item)
								}
							})
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			initTrainerOpts() {
				this.trainer_opts = [{
					...defaultTrainerOpts
				}]
				if (this.mguOnSet.trainer_id || this.mguOnSet.trainer_id_1st) {
					if (this.mguOnSet.trainer_id) {
						if (this.mguOnSet.trainer_id_1st) {
							this.trainer_opts.push({
								id: this.mguOnSet.trainer_id,
								name: (this.mguOnSet.trainer && this.mguOnSet.trainer.name) ? this.mguOnSet.trainer.name : '未知教练',
								phoneno: (this.mguOnSet.trainer && this.mguOnSet.trainer.phoneno) ? this.mguOnSet.trainer.phoneno : ''
							})
							if (this.mguOnSet.trainer_id != this.mguOnSet.trainer_id_1st) {
								this.trainer_opts.push({
									id: this.mguOnSet.trainer_id_1st,
									name: (this.mguOnSet.trainer_1st && this.mguOnSet.trainer_1st.name) ? this.mguOnSet.trainer_1st.name : '未知教练',
									phoneno: (this.mguOnSet.trainer_1st && this.mguOnSet.trainer_1st.phoneno) ? this.mguOnSet.trainer_1st.phoneno : ''
								})
							}
						} else {
							this.trainer_opts.push({
								id: this.mguOnSet.trainer_id,
								name: (this.mguOnSet.trainer && this.mguOnSet.trainer.name) ? this.mguOnSet.trainer.name : '未知教练',
								phoneno: (this.mguOnSet.trainer && this.mguOnSet.trainer.phoneno) ? this.mguOnSet.trainer.phoneno : ''
							})
						}
					} else {
						this.trainer_opts.push({
							id: this.mguOnSet.trainer_id_1st,
							name: (this.mguOnSet.trainer_1st && this.mguOnSet.trainer_1st.name) ? this.mguOnSet.trainer_1st.name : '未知教练',
							phoneno: (this.mguOnSet.trainer_1st && this.mguOnSet.trainer_1st.phoneno) ? this.mguOnSet.trainer_1st.phoneno : ''
						})
					}
				}
			},
			initPassword() {
				const pw = randStr(8)
				this.$confirm("该操作将重置登陆密码为 " + pw + "，确认记录后继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/User/setVip", {
						vip_id: this.mguOnSet.vip_id,
						password: hex_hmac(String(this.mguOnSet.vip_wechat_openid), hex(pw))
					}).finally(() => {
						this.onCloseModal();
					});
				})
			},
			initPayPassword() {
				const pw = randStr(8)
				this.$confirm("该操作将重置支付密码为 " + pw + "，确认记录后继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/User/setVip", {
						vip_id: this.mguOnSet.vip_id,
						pay_password: hex_hmac(String(this.mguOnSet.vip_wechat_openid), hex(pw))
					}).finally(() => {
						this.onCloseModal();
					});
				})
			},
			onSave() {
				this.$refs['user-form'].validate((valid) => {
					if (valid) {
						let _data = {
							name: this.mguOnSet.vip_name,
							nickname: this.mguOnSet.vip_nickname,
							sex: this.mguOnSet.vip_sex,
							birth_dt: Math.floor(this.mguOnSet.vip_birth_dt / 1000),
							medal_count: this.mguOnSet.medal_count,
							is_trainer: this.mguOnSet.is_trainer,
							is_adminer: this.mguOnSet.is_adminer,
							trainer_id: this.mguOnSet.trainer_id ? this.mguOnSet.trainer_id : 0,
							trainer_level: this.mguOnSet.trainer_level ? this.mguOnSet.trainer_level : 0,
							trainer_id_1st: this.mguOnSet.trainer_id_1st ? this.mguOnSet.trainer_id_1st : 0
						}
						if (this.showAddModal) {
							_data['phoneno'] = this.mguOnSet.vip_phoneno
							_data['gym_id'] = this.gym_id
							_data['user_iscover'] = this.user_foradd_iscover
							_data['mgu_iscover'] = this.mgu_foradd_iscover
							this.$confirm("该操作将预添加会员，是否添加?", "提示", {
								confirmButtonText: "添加",
								cancelButtonText: "取消",
								type: "info",
							}).then(() => {
								this.loading = true;
								this.$post("/admin/User/AddVip", _data).then((res) => {
									if (res.rst == 0) {
										this.$message.success(res.msg)
										this.getList();
										this.onCloseModal();
									}
								}).finally(() => {
									this.loading = false;
								})
							})
						} else {
							_data['vip_id'] = this.mguOnSet.vip_id
							_data['mgu_id'] = this.mguOnSet.mgu_id
							this.$confirm("该操作将修改会员信息，是否继续?", "提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "info",
							}).then(() => {
								this.loading = true;
								this.$post("/admin/User/setVip", _data).then((res) => {
									if (res.rst == 0) {
										this.$message.success(res.msg)
										this.getList();
										this.onCloseModal();
									}
								}).finally(() => {
									this.loading = false;
								})
							})
						}
					}
				})
			},
			onStatus(mgu_id, status) {
				this.$confirm(
					"该操作将「" + (0 == status ? "删除" : "恢复") + "」所选会员，是否继续?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "info",
					}
				).then(() => {
					this.$post("/admin/User/setVip", {
						mgu_id,
						status,
					}).finally(() => {
						this.getList();
					});
				});
			},
			onCloseModal() {
				this.showAddModal = false;
				this.showSetModal = false;
				this.mguOnSet = {
					...defaultMgu
				};
			},
			gotoPageSurvey(page_url = '', mgu_id = 0) {
				if (page_url && mgu_id) {
					this.$router.push({
						path: page_url,
						query: {
							mgu_id: mgu_id
						}
					})
				}
			}
		}
	};
</script>
<style lang="less" scoped>
	.vip {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.add-btn {
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;
			}
		}

		/deep/.el-form-item__label {
			text-align: left;
			float: none;
			word-break: break-word;
		}
	}
</style>