<template>
	<div class="head" style="position: relative;">

		<!-- <div class="fc_bright" :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" style="position:absolute;top:-10px;left:-20px;" @click="$emit('update:isCollapse', !isCollapse)"></div> -->

		<div style="margin:20px 20px 15px 20px; display: flex;align-items: center;justify-content: space-between;">
			<div class="fs_huge bold" style="display: flex;align-items: center;justify-content: flex-start;">
				<div class="fs_max fc_golden el-icon-monitor" style="cursor: pointer;" @click="routeToHome"></div>
				<div v-if="crumbs.length" class="crumbs f-r-s fc_gray fs_large" style="margin-left:20px;cursor: pointer;" @click="refresh">
					<div v-for="(item, index) in crumbs" :key="index">
						<div v-if="index === 0">{{ item }}</div>
						<div v-else>
							<span class="devide">/</span><span>{{ item }}</span>
						</div>
					</div>
				</div>
				<div v-else class="fc_golden" style="margin-left:10px;cursor:pointer;">
					首页
				</div>
			</div>

			<div class="fs_large" style="display: flex;align-items: center;justify-content: flex-end;">
				<img alt="avator" class="avator" :src="user.avatar_url" style="width: 60px;height:60px;border-radius: 5px;" />
				<div style="margin-left:20px;">
					<div class="fs_std fc_dark" style="display: flex;align-items: center;justify-content: flex-end;">
						<div>
							{{ user.name }}
							<sup v-if="1==user.gym.is_super" class="fs12">&emsp;管</sup>
							<sup v-if="1==user.is_root" class="fs12">&emsp;根</sup>
						</div>
						<div class="fc_blue" style="margin-left:20px;" @click="logout">退出</div>
					</div>
					<div>
						<el-select v-if="1!=user.is_root" v-model="user.gym.id" placeholder="请选择场馆" size="mini" @change="changeGym">
							<el-option v-for="(item,ix) in user.gyms" :key="ix" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-else v-model="remote_gym_id" filterable remote clearable placeholder="请输入场馆品牌、名称、联系电话" :remote-method="remoteRootGyms" :loading="remote_load" @change="changeGym(remote_gym_id)">
							<el-option v-for="item in remote_gyms" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
			</div>
		</div>

		<div style="width: 100%;height:6px;background:linear-gradient(to bottom, #808080, white 6px);"></div>

		<div class="tabs f-r-b" style="height:46px;background: linear-gradient(to bottom, white, white 40px, transparent 46px);">
			<div class="tabs-box">
				<div class="tab cp" style="background:#F8F8F8;" @click="handleCloseAll">
					<span class="fs18 fw5" style="margin-right: 16px;color:#808080;">全部</span>
					<span class="el-icon-error fs10" style="color:#808080;"></span>
				</div>
				<div v-for="page in routesMap" :key="page.name" :class="{tab: true,cp: true,'tab-active': page.name === currentPage}" @click="handleClick(page.name)">
					<span v-if="page.name === currentPage" class="dot"></span>
					<span class="fs18 fw5" style="margin-right: 16px">{{ page.title }}</span>
					<span v-if="routesMap.length > 1 && page.name !== currentPage" class="el-icon-error fs10" style="color: #EB5753" @click.stop="handleClose(page.name)"></span>
					<span v-if="routesMap.length > 1 && page.name === currentPage" class="el-icon-close fs10" @click.stop="handleClose(page.name)"></span>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import {
		mapState,
		mapActions
	} from "vuex";
	import {
		EventBus
	} from '../../eventBus.js'
	export default {
		name: "webhead",
		props: {
			isCollapse: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				remote_load: false,
				remote_gyms: [{
					id: '',
					name: '未选择',
					tel_no: ''
				}],
				remote_gym_id: ''
			}
		},
		computed: {
			...mapState(["user", "nav", "routesMap"]),
			crumbs() {
				const crumbs = [];
				for (let n of this.nav) {
					if (n.children) {
						for (let sub of n.children) {
							if (sub.route == this.$route.path) {
								crumbs.push(n.title);
								crumbs.push(sub.title);
							}
						}
					}
				}
				return crumbs;
			},
			currentPage() {
				return this.$route.name
			}
		},
		mounted() {
			if (!this.user.gyms || !Object.keys(this.user.gyms).length) {
				this.logout()
			} else {
				this.remote_gyms = this.user.gyms
				this.remote_gym_id = this.user.gym.id
				// Object.keys(this.user.gyms).forEach((gid, gix) => {
				//     this.user_gyms_opts.push({ id: gid, name: this.user.gyms[gid].name })
				// })
				// if (this.user.gymed_id) {
				//     this.user_gymed_id = this.user.gymed_id
				// } else {
				//     this.changeGym(Object.keys(this.user.gyms)[0])
				// }
				//this.changeGym(this.user.gyms[0].id)
			}
		},
		methods: {
			...mapActions(['updateRoutes', 'updateUser', 'userLogout']),
			handleCloseAll() {
				const newRoutesMap = this.routesMap.filter((p, i) => {
					return (p.name === 'home')
				})
				this.updateRoutes(newRoutesMap)
				if ('home' !== this.currentPage) {
					this.$router.push('/home')
				}
			},
			handleClick(targetName) {
				if (targetName == this.currentPage) return
				const path = this.routesMap.find(p => p.name == targetName).path
				this.$router.push(path)
			},
			handleClose(targetName) {
				if (this.routesMap.length <= 1) return
				if (this.currentPage === targetName) {
					let index = 0
					const newRoutesMap = this.routesMap.filter((p, i) => {
						if (p.name == targetName) {
							index = i
						}
						return (p.name != targetName)
					})
					this.updateRoutes(newRoutesMap)
					index = index > newRoutesMap.length - 1 ? index - 1 : index
					const path = newRoutesMap[index].path
					this.$router.push(path)
				} else {
					this.updateRoutes(this.routesMap.filter(p => p.name != targetName))
				}
			},
			refresh() {
				window.location.reload();
			},
			routeToHome() {
				if (this.$router.currentRoute.path != '/home') {
					this.$router.replace('/home');
				}
			},
			remoteRootGyms(query) {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/Index/remoteRootGyms", {
						user_id: this.user.id,
						cdit: query
					}).then(res => {
						if (res.rst == 0) {
							this.remote_gyms = res.data;
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			changeGym(e) {
				if (e > 0) {
					this.$post('/admin/Index/changeGym', {
						user_id: this.user.id,
						gym_id: e
					}).then(res => {
						if (res.rst == 0) {
							this.user.gym = res.data
							this.updateUser(this.user)
							EventBus.$emit('updateGymedRules')
							this.updateRoutes([])
							//this.$router.replace('/');
							setTimeout(() => {
								if (this.$router.currentRoute.path != '/home') {
									this.$router.replace('/home');
								} else {
									window.location.reload()
								}
							}, 100)
						}
					})
				}
			},
			logout() {
				this.userLogout()
				document.cookie = 'df_gym_token=0;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
				this.$router.push('/login')
			}
		}
	};
</script>
<style lang="less">
	.head {
		height: 140px;

		.tabs {
			height: 40px;
			padding: 0 20px;

			.tabs-box {
				flex: 1;
				overflow-x: auto;
				white-space: nowrap;

				.tab {
					display: inline-block;
					height: 38px;
					line-height: 36px;
					background: #ffffff;
					color: #666666;
					border: 1px solid #d0d0d0;
					padding: 0 12px;
					margin-right: 10px;

					&:last-child {
						margin-right: 0;
					}

					.close {
						display: inline-block;
						width: 14px;
						height: 14px;
						line-height: 14px;
						border-radius: 50%;
						background-color: #eb5753;
					}
				}

				.tab-active {
					background-color: #65b687;
					color: #ffffff;

					.dot {
						display: inline-block;
						width: 10px;
						height: 10px;
						background: #ffffff;
						border-radius: 50%;
						margin-right: 13px;
					}

					.close {
						background-color: transparent;
					}
				}
			}
		}
	}
</style>